(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/account-selector/assets/javascripts/services/fetch-retail-account.js') >= 0) return;  svs.modules.push('/components/marketplace-data/account-selector/assets/javascripts/services/fetch-retail-account.js');
"use strict";


const {
  jupiter
} = svs.core;
const mapActivatedDraw = activatedDraw => ({
  buyinCloseTime: activatedDraw.buyinCloseTime,
  groupId: activatedDraw.groupId.toString(),
  name: activatedDraw.name,
  balance: Number(activatedDraw.currentPlayableAmount.replace(/,/g, '.')),
  activatedDrawId: activatedDraw.activatedDrawId.toString(),
  containerId: Number(activatedDraw.containerId),
  sharePrice: Number(activatedDraw.sharePrice.replace(/,/g, '.')),
  commissionPercent: activatedDraw.commissionPercent,
  soldShares: activatedDraw.soldShares,
  originalCommission: activatedDraw.commissionPercent
});
const fetchRetailAccount = (activatedDrawId, callback) => {
  jupiter.get("/retailer/1/marketplace/activateddraws/".concat(activatedDrawId), response => {
    const activatedDraw = response.activatedDraw;
    callback(undefined, [mapActivatedDraw(activatedDraw)]);
  }, error => callback(error, []));
};
setGlobal('svs.components.marketplaceData.accountSelector.services.fetchRetailAccount', fetchRetailAccount);
setGlobal('svs.components.marketplaceData.accountSelector.services.mapActivatedDraw', mapActivatedDraw);

 })(window);