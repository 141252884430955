(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/account-selector/assets/javascripts/services/fetch-available-accounts.js') >= 0) return;  svs.modules.push('/components/marketplace-data/account-selector/assets/javascripts/services/fetch-available-accounts.js');
"use strict";


const {
  jupiter
} = svs.core;
const fetchAvailableAccounts = (drawNumber, productId, callback) => {
  jupiter.get("/marketplace/1/activateddraw/balance?product=".concat(svs.utils.products.getSpecificDrawName(productId), "&drawNumber=").concat(drawNumber), response => {
    const accounts = response.activatedDraws;
    callback(undefined, accounts.map(account => ({
      buyinCloseTime: account.BuyinCloseTime,
      groupId: account.GroupId.toString(),
      name: account.GroupName,
      balance: account.Balance.toString(),
      activatedDrawId: account.ActivatedDrawId.toString(),
      containerId: Number(account.ContainerId),
      sharePrice: Number(account.SharePrice.replace(/,/g, '.'))
    })));
  }, error => callback(error, []));
};
setGlobal('svs.components.marketplaceData.accountSelector.services.fetchAvailableAccounts', fetchAvailableAccounts);

 })(window);